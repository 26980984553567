import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (
    <svg version="1.0" 
    xmlns="http://www.w3.org/2000/svg" 
    height="40px" 
    viewBox="0 0 1125.000000 830.000000"
    preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,830.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M6295 6688 c-44 -5 -93 -9 -110 -8 -34 0 -182 -32 -237 -52 -20 -8
-46 -22 -57 -32 -10 -10 -61 -36 -112 -58 -51 -22 -105 -48 -120 -59 -15 -10
-31 -19 -36 -19 -5 0 -24 -13 -43 -28 -19 -16 -53 -39 -75 -52 -22 -13 -53
-35 -70 -49 -54 -47 -285 -286 -298 -307 -7 -12 -30 -48 -52 -80 -21 -33 -61
-102 -88 -154 -26 -52 -56 -103 -66 -113 -17 -18 -20 -18 -57 -2 -135 58 -626
59 -765 1 -19 -8 -63 -22 -99 -31 -98 -24 -430 -199 -430 -226 0 -4 -17 -18
-37 -30 -46 -29 -203 -187 -203 -204 0 -8 -21 -37 -46 -67 -25 -29 -70 -98
-99 -153 -52 -96 -115 -239 -115 -260 0 -29 -22 -84 -36 -89 -9 -3 -88 -11
-175 -16 -133 -8 -177 -15 -262 -41 -108 -33 -232 -83 -257 -104 -8 -7 -30
-17 -48 -23 -32 -11 -213 -153 -277 -218 -119 -121 -165 -183 -233 -319 -39
-77 -76 -162 -83 -190 -6 -27 -17 -61 -24 -75 -38 -78 -45 -495 -10 -610 9
-30 21 -77 27 -105 15 -70 153 -351 195 -397 11 -13 26 -34 33 -47 7 -13 42
-51 78 -85 36 -33 72 -69 81 -79 9 -10 41 -35 71 -55 30 -20 72 -51 93 -68 21
-18 51 -35 65 -39 15 -4 48 -20 75 -36 26 -16 53 -29 60 -29 6 0 39 -12 72
-26 33 -13 119 -36 190 -49 l130 -25 2635 0 2636 0 144 25 c182 32 299 73 505
177 82 41 207 132 282 203 23 22 48 47 57 55 15 14 61 69 132 157 26 32 54 76
154 243 19 32 63 149 87 230 13 47 31 110 40 140 13 44 17 104 17 295 1 239 0
241 -32 370 -18 72 -42 150 -54 175 -62 135 -102 213 -132 259 -19 28 -38 61
-41 72 -4 12 -24 39 -44 59 -63 65 -90 98 -101 122 -6 13 -28 36 -48 51 -20
15 -59 46 -87 68 -118 95 -196 150 -224 155 -17 4 -37 17 -46 30 -9 14 -25 24
-38 24 -13 0 -40 9 -61 20 -60 30 -212 78 -315 100 -52 10 -101 26 -109 34
-11 10 -18 48 -23 118 -4 57 -12 121 -17 143 -21 85 -52 197 -71 257 -28 92
-181 397 -203 406 -4 2 -24 28 -44 58 -56 84 -342 364 -372 364 -6 0 -23 11
-39 24 -28 23 -65 44 -153 87 -25 12 -47 25 -50 29 -6 9 -151 70 -166 70 -7 0
-14 7 -18 15 -3 10 -19 15 -43 15 -21 0 -47 6 -58 14 -20 14 -133 36 -209 41
-22 2 -83 6 -135 10 -54 4 -115 2 -138 -3 -25 -6 -43 -6 -43 -1 0 10 -13 10
-125 -3z m499 -264 c61 -14 130 -32 154 -40 107 -36 139 -49 164 -66 14 -10
29 -18 34 -18 14 0 169 -93 206 -124 168 -138 154 -126 218 -194 83 -87 215
-290 240 -367 7 -22 20 -56 30 -75 54 -106 83 -233 111 -490 17 -158 26 -180
85 -212 82 -46 96 -50 184 -55 47 -3 94 -11 105 -18 11 -7 36 -16 55 -20 57
-13 190 -67 265 -110 259 -145 519 -457 596 -716 70 -237 77 -538 18 -743 -27
-89 -77 -217 -95 -236 -7 -9 -21 -36 -29 -60 -9 -25 -22 -48 -30 -53 -9 -4
-26 -29 -39 -55 -28 -54 -238 -267 -289 -294 -19 -9 -37 -24 -40 -32 -3 -8
-23 -19 -44 -26 -21 -7 -49 -22 -63 -35 -27 -24 -224 -105 -256 -105 -10 0
-33 -8 -49 -18 -65 -40 -184 -42 -2830 -42 -1640 0 -2574 3 -2655 10 -129 10
-151 17 -320 88 -124 53 -284 171 -366 268 -51 62 -133 185 -161 244 -72 150
-119 387 -104 522 24 199 35 264 47 278 7 8 16 29 20 45 12 53 107 225 156
284 60 72 180 190 193 191 6 0 33 16 60 36 45 32 103 62 237 122 26 12 66 24
90 28 24 4 68 13 98 20 34 9 111 14 211 14 86 0 169 5 185 11 43 16 75 63 90
132 28 131 107 321 164 392 17 21 30 41 30 45 0 30 243 282 285 296 11 4 44
25 73 47 72 55 227 119 394 164 76 20 412 30 468 14 126 -37 216 -55 292 -59
82 -3 87 -2 99 20 6 13 20 32 30 43 10 12 28 48 39 80 11 33 32 77 45 97 14
21 25 41 25 46 0 5 31 56 68 113 68 102 244 294 308 335 16 10 52 35 79 55 49
36 91 61 190 112 53 28 206 81 265 92 19 4 60 15 90 26 46 15 87 18 259 19
185 0 215 -2 315 -26z"/>
</g>
</svg>
  );
}